import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AccountingBasisDTO, PriceGuaranteeDTO, PriceGuaranteeUpdateDTO } from '@ovex/price-guarantee-web-api';

import { Button, ButtonBar, Modal, Tabs } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import PriceGuaranteeFormHeader from '../PriceGuaranteeFormHeader/PriceGuaranteeFormHeader';
import PriceGuaranteeTabs from '../PriceGuaranteeTabs';
import PriceGuaranteeFormAccounting from '../PriceGuaranteeAccountingForm/PriceGuaranteeFormAccounting';

const propTypes = {
  accountingBasisList: PropTypes.arrayOf(PropTypes.instanceOf(AccountingBasisDTO)),
  editRules: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  onGetAccountingBasisList: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  priceGuarantee: PropTypes.instanceOf(PriceGuaranteeDTO).isRequired,
  shown: PropTypes.bool.isRequired
};

const defaultProps = {
  accountingBasisList: null,
  editRules: [],
  onSave: null,
  priceGuarantee: null
};

const PriceGuaranteeModalForm = (props) => {
  const lsi = React.useContext(LsiContext);

  const readOnly = !props.onSave;
  const [rowData1, setRowData1] = React.useState(readOnly ? props.priceGuarantee.pgAccountingList : props.accountingBasisList);

  const tabFormValues = React.useRef({});

  const headerFormRef = React.useRef(null);
  const pgAccountingTableRef = React.useRef(null);

  const [pg, setPG] = React.useState(props.priceGuarantee);
  React.useEffect(
    () => {
      setPG(props.priceGuarantee);
      setRowData1(readOnly ? props.priceGuarantee.pgAccountingList : props.accountingBasisList);
    },
    [props.accountingBasisList, props.priceGuarantee, readOnly]
  );
  const onGetPGAccountingList = props.onGetAccountingBasisList;
  const handleGetAccountingBasisDataList = React.useCallback(async () => {
    await onGetPGAccountingList();
  }, [onGetPGAccountingList]);

  React.useEffect(
    () => {
      if (!readOnly) {
        handleGetAccountingBasisDataList()
          .catch(() => setRowData1(props.priceGuarantee.pgAccountingList));
      }
    }, [handleGetAccountingBasisDataList, onGetPGAccountingList, props.priceGuarantee.pgAccountingList, readOnly]);

  const handleSaveTabFrom = React.useCallback(
    ({ formId, values }) => {
      tabFormValues.current[formId] = values;
    },
    []
  );

  const [selectedTab, setSelectedTab] = React.useState(PriceGuaranteeTabs.header);

  const handleSetSelectedTab = React.useCallback(
    (opt) => {
      setSelectedTab(opt.tab.props.name);
    },
    []
  );

  const handleNewPgAccountingList = (pgAccountingList, selectedAccountingList) => {
    return selectedAccountingList?.filter(pgAccounting =>
      !pgAccountingList.some(selectedPgAccounting =>
        pgAccounting.packageCode === selectedPgAccounting.packageCode && pgAccounting.year === selectedPgAccounting.year));
  };
  const handleDeletePgAccountingList = (pgAccountingList, selectedAccountingList) => {
    // Pokud je selectedAccountingList prázdný nebo undefined, vrať všechny ID kde suppressed === false
    if (!selectedAccountingList || selectedAccountingList.length === 0) {
      return pgAccountingList
        .filter(pgAccounting => !pgAccounting.suppressed) // Vyloučí suppressed === true
        .map(pgAccounting => pgAccounting.id);
    }

    return pgAccountingList
      .filter(pgAccounting =>
        !pgAccounting.suppressed && // Vyloučí suppressed === true
        !selectedAccountingList.some(selectedPgAccounting =>
          pgAccounting.packageCode === selectedPgAccounting.packageCode &&
          pgAccounting.year === selectedPgAccounting.year
        )
      )
      .map(pgAccounting => pgAccounting.id);
  };

  const onSave = props.onSave;
  const handleSave = React.useCallback(() => {
    if (headerFormRef.current.isValid()) {
      headerFormRef.current.save();
      if (pgAccountingTableRef.current.isValid()) {
        pgAccountingTableRef.current.save();
        const newPGAccountingBasisList = handleNewPgAccountingList(pg.pgAccountingList, tabFormValues.current[PriceGuaranteeTabs.pgAccountingList].pgAccountingList);
        const deletePGAccountingBasisList = handleDeletePgAccountingList(pg.pgAccountingList, tabFormValues.current[PriceGuaranteeTabs.pgAccountingList].pgAccountingList);
        const updateDTO = PriceGuaranteeUpdateDTO.constructFromObject({
          id: pg.id,
          ...tabFormValues.current[PriceGuaranteeTabs.header],
          newPGAccountingBasisList,
          deletePGAccountingBasisList
        });
        onSave(updateDTO);
      } else {
        setSelectedTab(PriceGuaranteeTabs.pgAccountingList);
      }
    } else {
      setSelectedTab(PriceGuaranteeTabs.header);
    }
  }, [onSave, pg.id, pg.pgAccountingList]);

  const modalTitle = readOnly ? 'PRICE_GUARANTEE.PAGE_TITLE.PRICE_GUARANTEE_DETAIL' : 'PRICE_GUARANTEE.PAGE_TITLE.PRICE_GUARANTEE_UPDATE';
  return (
    <Modal
      className='ovex-pg-PriceGuaranteeModalForm'
      header={lsi.getLSIItem(modalTitle)}
      onClose={props.onClose}
      shown={props.shown}
      size='m'
    >
      <Tabs activeName={selectedTab} onChange={handleSetSelectedTab}>
        <Tabs.Item
          header={lsi.getLSIItem('PRICE_GUARANTEE.TABS.HEADER')}
          name={PriceGuaranteeTabs.header}>
          <PriceGuaranteeFormHeader
            editRules={props.editRules}
            onClose={props.onClose}
            onSave={handleSaveTabFrom}
            priceGuarantee={pg}
            ref={headerFormRef}
          />
        </Tabs.Item>
        <Tabs.Item
          header={lsi.getLSIItem('PRICE_GUARANTEE.TABS.PG_PACKAGE_LIST')}
          name={PriceGuaranteeTabs.pgAccountingList}>
          <PriceGuaranteeFormAccounting
            editRules={props.editRules}
            marketingActionCode={props.priceGuarantee.marketingActionCode}
            onSave={handleSaveTabFrom}
            pgAccountingList={props.priceGuarantee.pgAccountingList}
            ref={pgAccountingTableRef}
            rowData={rowData1}
          />
        </Tabs.Item>
      </Tabs>
      <Fragment>
        { !readOnly &&
        <ButtonBar
          align={ButtonBar.align.center}
          formControlsBar
        >
          <Button
            content={lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.CANCEL')}
            onClick={props.onClose}
            type={Button.type.SECONDARY}
          />
          <Button
            content={lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.SAVE')}
            onClick={handleSave}
            type={Button.type.PRIMARY}
          />
        </ButtonBar>
        }
      </Fragment>
    </Modal>
  );
};

PriceGuaranteeModalForm.propTypes = propTypes;
PriceGuaranteeModalForm.defaultProps = defaultProps;

export default PriceGuaranteeModalForm;
